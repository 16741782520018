<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        Nombre d'établissements par départements, communes, arrondissements et quartiers
      </b-card-title>
      <!-- <b-dropdown
        text="Choisir une periode"
        variant="transparent"
        class="chart-dropdown"
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in chartData.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <b-card-body>
      <!-- apex chart -->
      <div
        v-if="false"
        class="row"
      >
        <div class="col-lg-6">
          <h5>Par département</h5>
          <vue-apex-charts
            v-if="!isLoadingDepartements"
            type="pie"
            height="325"
            class="mt-2 mb-1"
            :options="departementsPie.chartOptions"
            :series="departementsPie.series"
          />
        </div>
        <div class="col-lg-6">
          <h5>Par communes</h5>
          <vue-apex-charts
            v-if="!isLoadingCommunes"
            type="pie"
            height="325"
            class="mt-2 mb-1"
            :options="communesPie.chartOptions"
            :series="communesPie.series"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <!-- <h5>
            Liste du nombre d'établissements</h5> -->

          <div class="mb-2">
            <div class="mb-1">
              Filtrer par
            </div>
            <div class="row">
              <div class="col">
                <b-form-select
                  v-model="selectedTypeLocalisation"
                  :options="typesLocalisation"
                  html-field="name"
                  value-field="key"
                />
              </div>
              <div class="col">
                <b-form-input
                  v-model="localSearchQuery"
                  placeholder="Rechercher par departements, communes, arrondissements ou quartiers"
                  @keyup="localFilter"
                />
              </div>
            </div>
          </div>
          <b-table
            responsive
            :items="getTableData"
            :fields="tableColumns"
            :busy="isLoadingTableData"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>
          </b-table>

          <PaginationComponent
            :table-data="getTableData"
            :per-page="paginationData.perPage"
            :total-rows="paginationData.total"
            @change="onPaginationChange"
          />
        </div>
      </div>

      <!-- list group -->
      <div
        v-if="false"
        class="pt-25"
      >
        <div
          v-for="(data, index) in chartData.listData"
          :key="index"
          class="d-flex justify-content-between"
          :class="
            index === Object.keys(chartData.listData).length - 1 ? '' : 'mb-1'
          "
        >
          <div class="series-info">
            <feather-icon
              :icon="data.icon"
              size="16"
              class="mr-50"
              :class="data.iconColor"
            />
            <span class="font-weight-bolder">{{ data.text }}</span>
          </div>
          <span>{{ data.result }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BTable,
  BFormSelect,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { mapActions, mapState } from 'vuex'
import PaginationComponent from '@/components/PaginationComponent.vue'

const customersPie = {
  series: [690, 258, 149],
  chartOptions: {
    chart: {
      toolbar: {
        show: false,
      },
    },
    labels: ['Billeterie', 'Voyage', 'Croisière', 'Autres'],
    dataLabels: {
      enabled: false,
    },
    legend: { show: false },
    stroke: {
      width: 4,
    },
    colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
  },
}
const departementsPie = {
  series: [690, 258, 149],
  chartOptions: {
    chart: {
      toolbar: {
        show: false,
      },
    },
    labels: ['Billeterie', 'Voyage', 'Croisière', 'Autres'],
    dataLabels: {
      enabled: false,
    },
    legend: { show: false },
    stroke: {
      width: 4,
    },
    colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
  },
}
const communesPie = {
  series: [690, 258, 149],
  chartOptions: {
    chart: {
      toolbar: {
        show: false,
      },
    },
    labels: ['Billeterie', 'Voyage', 'Croisière', 'Autres'],
    dataLabels: {
      enabled: false,
    },
    legend: { show: false },
    stroke: {
      width: 4,
    },
    colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
  },
}

// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import statisticsStoreModule from "@/store/statistics";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "statistics", module: statisticsStoreModule },
    ];

    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },   
  components: {
    PaginationComponent,
    BSpinner,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
    BTable,
    BFormSelect,
    BFormInput,
  },
  data() {
    return {
      selectedTypeLocalisation: 'departements',
      selectedTypeEtablissement: '',
      isLoadingDepartements: false,
      isLoadingCommunes: false,
      isLoadingArrondissements: false,
      isLoadingQuartiers: false,
      isLoadingTableData: false,
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      localSearchQuery: '',
      tableDatas: {
        departements: [],
        communes: [],
        arrondissements: [],
        villages: [],
      },
      filteredTableDatas: {
        departements: [],
        communes: [],
        arrondissements: [],
        villages: [],
      },
      typesLocalisation: [
        { name: 'Départements', key: 'departements' },
        { name: 'Communes', key: 'communes' },
        { name: 'Arrondissements', key: 'arrondissements' },
        { name: 'Quartiers', key: 'quartiers' },
      ],
      chartData: {},
      customersPie,
      departementsPie,
      communesPie,
      tableColumns: [
        { key: 'name', label: 'Nom', sortable: true },
        { key: 'nbrEts', label: "Nombre d'établissements", sortable: true },
      ],
      typesEtablissementsOptions: [],
      paginationData: {
        perPage: 10,
        total: 0,
      },
    }
  },
  watch: {
    localSearchQuery: {
      handler(val, old) {},
    },
    selectedTypeLocalisation: {
      handler(val, old) {
        this.filteredTableDatas[this.selectedTypeLocalisation] = this.tableDatas[this.selectedTypeLocalisation]
      },
    },
    tableDatas: {
      deep: true,
      immediate: true,
      handler(val, old) {
        this.filteredTableDatas[this.selectedTypeLocalisation] = val[this.selectedTypeLocalisation]
      },
    },
  },
  async mounted() {
    await this.getStatisticsAgencesVoyageToursOperators()
  },
  methods: {
    ...mapActions('params', {
      action_loadDepartments: 'loadDepartments',
      action_loadCommunes: 'loadCommunes',
      action_loadArrondissements: 'loadArrondissements',
      action_loadNeighborhoods: 'loadNeighborhoods',
    }),
    ...mapActions('statistics', {
      action_getStatisticsAgencesVoyageToursOperators:
        'getStatisticsAgencesVoyageToursOperators',
    }),
    ...mapActions('etablissements', {
      action_getListTypesEtablissement: 'getListTypesEtablissement',
    }),
    onPaginationChange(val) {
      // console.log('paginationChange', val)
      this.paginationData = { ...this.paginationData, ...val }
    },
    localFilter() {
      const query = this.localSearchQuery.toLowerCase()
      this.filteredTableDatas[this.selectedTypeLocalisation] = this.tableDatas[
        this.selectedTypeLocalisation
      ].filter(e => {
        if (!query) return true
        return e.name.toLowerCase().includes(query)
      })
      this.filteredTableDatas[this.selectedTypeLocalisation] = this.filteredTableDatas[this.selectedTypeLocalisation].filter(
        e => true,
      )
    },
    getRandomInt(min = 10, max = 1000) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    async getStatisticsAgencesVoyageToursOperators() {
      this.isLoadingTableData = true
      const fieldKey = 'field'
      const typeEtablissementQueryId = 'typeEtablissement'
      const secteurQueryId = 'secteur'
      const departementQueryId = 'departement'
      const communeQueryId = 'commune'
      const arrondissementQueryId = 'arrondissement'
      const quartierQueryId = 'quartier'
      const queryParams = {
        fields: [
          '_id',
          departementQueryId,
          communeQueryId,
          arrondissementQueryId,
          quartierQueryId,
          secteurQueryId,
          typeEtablissementQueryId,
        ],
      }
      await this.action_getStatisticsAgencesVoyageToursOperators({
        params: queryParams,
      })
        .then(async res => {
          await this.loadDepartments()
          await this.loadCommunes()
          await this.loadArrondissements()
          await this.loadNeighborhoods()
          await this.getListTypesEtablissement()
          const { data } = res
          const { resources } = data
          const departements = this.departements.map(e => ({
            name: e.name,
            nbrEts: 0,
          }))

          const communes = this.communes.map(e => ({
            name: e.name,
            nbrEts: 0,
          }))
          const arrondissements = this.arrondissements.map(e => ({
            name: e.name,
            nbrEts: 0,
          }))
          const quartiers = this.quartiers.map(e => ({
            name: e.name,
            nbrEts: 0,
          }))
          if (resources && Array.isArray(resources)) {
            // departements
            const statsDepartements = resources.find(
              e => e[fieldKey] === departementQueryId,
            )
            const statsDepartementsValues = this.processEtsCount(statsDepartements)
            statsDepartementsValues.forEach(element => {
              const itemKey = Object.keys(element)[0]
              const itemValue = Object.values(element)[0]
              const foundIndex = departements.findIndex(
                e => e.name === itemKey,
              )
              if (foundIndex !== -1) { departements[foundIndex].nbrEts = itemValue }
            })
            this.tableDatas[`${departementQueryId}s`] = departements

            // communes
            const statsCommunes = resources.find(
              e => e[fieldKey] === communeQueryId,
            )
            const statsCommunesValues = this.processEtsCount(statsCommunes)
            statsCommunesValues.forEach(element => {
              const itemKey = Object.keys(element)[0]
              const itemValue = Object.values(element)[0]
              const foundIndex = communes.findIndex(e => e.name === itemKey)
              if (foundIndex !== -1) communes[foundIndex].nbrEts = itemValue
            })
            this.tableDatas[`${communeQueryId}s`] = communes

            // arrondissements
            const statsArrondissements = resources.find(
              e => e[fieldKey] === arrondissementQueryId,
            )
            const statsArrondissementsValues = this.processEtsCount(statsArrondissements)
            statsArrondissementsValues.forEach(element => {
              const itemKey = Object.keys(element)[0]
              const itemValue = Object.values(element)[0]
              const foundIndex = arrondissements.findIndex(
                e => e.name === itemKey,
              )
              if (foundIndex !== -1) { arrondissements[foundIndex].nbrEts = itemValue }
            })
            this.tableDatas[`${arrondissementQueryId}s`] = arrondissements

            // quarters
            const statsQuartiers = resources.find(
              e => e[fieldKey] === arrondissementQueryId,
            )
            const statsQuartiersValues = this.processEtsCount(statsQuartiers)
            statsQuartiersValues.forEach(element => {
              const itemKey = Object.keys(element)[0]
              const itemValue = Object.values(element)[0]
              const foundIndex = quartiers.findIndex(e => e.name === itemKey)
              if (foundIndex !== -1) quartiers[foundIndex].nbrEts = itemValue
            })
            this.tableDatas[`${quartierQueryId}s`] = quartiers
          }
          this.isLoadingTableData = false

          // console.log('stats', res)
        })
        .catch(error => {
          this.isLoadingTableData = false

          // console.log(error)
        })
    },
    async loadDepartments() {
      this.isLoadingDepartements = true
      await this.action_loadDepartments()
        .then(res => {
          this.departements = res
          // this.departementsPie.chartOptions.labels = res
          //   .map(e => e.name)
          //   .filter(e => true)
          // this.departementsPie.series = res.map(e => this.getRandomInt())
          // const departements = res.map(e => ({
          //   name: e.name,
          //   "Nombre d'établissement": this.getRandomInt(),
          // }))

          // this.tableDatas.departements = departements

          this.isLoadingDepartements = false
        })
        .catch(err => {
          this.isLoadingDepartements = false
        })
    },
    async loadCommunes() {
      this.isLoadingCommunes = true

      await this.action_loadCommunes()
        .then(res => {
          this.communes = res
          // this.communesPie.chartOptions.labels = res
          //   .map(e => e.name)
          //   .filter(e => true)
          // this.communesPie.series = res.map(e => this.getRandomInt())

          // const communes = res.map(e => ({
          //   name: e.name,
          //   "Nombre d'établissement": this.getRandomInt(),
          // }))
          // this.tableDatas.communes = communes
          this.isLoadingCommunes = false
        })
        .catch(err => {
          this.isLoadingCommunes = false
        })
    },
    async loadArrondissements() {
      this.isLoadingArrondissements = true

      await this.action_loadArrondissements()
        .then(res => {
          this.arrondissements = res
        })
        .catch(err => {
          this.isLoadingArrondissements = false
        })
    },
    async loadNeighborhoods() {
      this.isLoadingQuartiers = true

      await this.action_loadNeighborhoods()
        .then(res => {
          // console.log(res)
          this.quartiers = res
          // const quartiers = res.map(e => ({
          //   name: e.name,
          //   "Nombre d'établissement": this.getRandomInt(),
          // }))
          // this.tableDatas.quartiers = quartiers
          this.isLoadingQuartiers = false
        })
        .catch(err => {
          this.isLoadingQuartiers = false
        })
    },
    getListTypesEtablissement() {
      this.action_getListTypesEtablissement()
        .then(res => {
          // console.log("typeets", res);
          this.typesEtablissementsOptions = res.data.resources || []
        })
        .catch(err => {})
    },
    processEtsCount(resources) {
      const { resourcesCount, values } = resources || {}
      let result = []
      if (
        Array.isArray(resourcesCount)
        && Array.isArray(values)
        && resourcesCount.length === values.length
      ) {
        // eslint-disable-next-line comma-spacing
        result = values.map((e, i) => ({ [e]: resourcesCount[i] }))
      }
      return result
    },
  },
  computed: {
    getTableData() {
      const items = [...this.filteredTableDatas[this.selectedTypeLocalisation]]
      this.paginationData.total = items.length || 0
      const paginateArray = (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage)
      const { perPage, currentPage } = this.paginationData
      // console.log(items, this.paginationData)
      return paginateArray(items, perPage, currentPage || 1)
      return this.filteredTableDatas[this.selectedTypeLocalisation]
    },
    getTableColumsTitle() {
      return [
        {
          key: 'departement',
          label: 'Deoartement',
          sortable: true,
        },
        {
          key: '',
          label: "Nombre d'établissement",
          sortable: true,
        },
      ]
    },
  },

  created() {
    this.chartData = {
      lastDays: ['28 Derniers jours', 'Mois dernier', 'Annee Dernière'],
      listData: [
        {
          icon: 'CircleIcon',
          iconColor: 'text-primary',
          text: 'Billeterie',
          result: 890,
        },
        {
          icon: 'CircleIcon',
          iconColor: 'text-warning',
          text: 'Voyage',
          result: 258,
        },
        {
          icon: 'CircleIcon',
          iconColor: 'text-danger',
          text: 'Croisière',
          result: 149,
        },
        {
          icon: 'CircleIcon',
          iconColor: 'text-danger',
          text: 'Autres',
          result: 149,
        },
      ],
    }
  },
}
</script>
